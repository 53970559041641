module controllers {
    export module master {

        interface ICustomerCtrlScope extends ng.IScope {
        }

        interface ICustomerParams extends ng.ui.IStateParamsService {
        }

        export class customerListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "customerService",
                "$state",
                "$stateParams",
                "$transitions",
            ];
            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.ICustomerDisplay>;
            searchAccordian: boolean = false;
            myHook: any;
            
            customerSearch: interfaces.master.ICustomerSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;
            
            sortName: string;
            sortDirection: string;

            constructor(private $scope: ICustomerCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private customerService: interfaces.master.ICustomerService,
                private $state: ng.ui.IStateService,
                private $stateParams: ICustomerParams,
                private $transitions: ng.ui.core.ITransition
            ) {
                this.customerSearch = {
                };
                
                this.ctrlName = 'Customer List Controller';

                this.loadCustomers();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Customer',
                    from: 'auth.MasterData.Customer.**'
                }, () => {
                    this.loadCustomers();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadCustomers() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'customerList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.customerSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.customerService.getList().query(params, (result: Array<interfaces.master.ICustomerDisplay>) => {
                            this.gvwCustomers.data = result;
                            if (result[0]) {
                                this.gvwCustomers.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwCustomers.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            public gvwCustomers: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.customerListCtrl.editCustomer(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Name",
                    displayName: "Name",
                    field: "Name",
                    width: 300,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "AlternateReference",
                    displayName: "Alternate Reference",
                    field: "AlternateReference",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CountryCode",
                    displayName: "Country",
                    field: "CountryCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CompanyType",
                    displayName: "Type",
                    field: "CompanyType",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "IncotermCode",
                    displayName: "Incoterm",
                    field: "IncotermCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "IsZeroRated",
                    displayName: "Zero Rated?",
                    field: "IsZeroRated",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "CompanyStatus",
                    displayName: "Status",
                    field: "CompanyStatus",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }
                ]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.comListFilterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.comListShortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            comListShortChange(gridApi) {
                this.DoSearch();
            }

            comListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            public editCustomer(id: number) {
                this.$state.go("auth.MasterData.Customer.Update", { comId: id });
            }

            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.master.ICustomerDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            showAll_click() {
                this.customerSearch = {
                };
                if (this.gridApi !== undefined) {
                    this.gridApi.grid.clearAllFilters(true, true, true).then(() => {
                        this.gridApi.grid.resetColumnSorting(null);
                    });
                }

                this.searchAccordian = false;
                this.DoSearch();
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.customerSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.customerService.getCustomerListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwCustomers.data = [];
                    this.gvwCustomers.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'customerList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.customerSearch, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.customerService.getList().query(params, (result: Array<interfaces.master.ICustomerDisplay>) => {
                            var currentDirtyRows: Array<interfaces.master.ICustomerDisplay> = [];

                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.master.ICustomerDisplay = _.find(result, _.matchesProperty('Id', value.Id));

                                if (row) {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });

                            this.gvwCustomers.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwCustomers.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwCustomers.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }


            UploadFromExcel() {
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Upload from Excel</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="cominv.excelupload">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">

                                        <div class="row">
                                            <div class="row vertical-align">
                                                <div class="col-md-8">
                                                    <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit">
                                                        <span>Select File</span>
                                                        <input name="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit" class="upload" type="file" id="docFileToUpload" ng-model="uploadExcelCtrl.repositoryFile" base-sixty-four-input accept=".xlsx" />
                                                    </label>
                                                    <span>{{uploadExcelCtrl.repositoryFile.filename}}</span>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!uploadExcelCtrl.repositoryFile || uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.upload()">Submit</button>
                                        <button class="btn btn-info" type="button" ng-disabled="uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class uploadExcelCtrl {

                        disableSubmit: boolean = false;
                        messages: interfaces.applicationcore.IMessage[];
                      
                        repositoryFile: interfaces.applicationcore.IRepositoryFile;
                        fileTemplate: interfaces.applicationcore.IDropdownModel;

                        fileTemplateList: interfaces.applicationcore.IDropdownModel[] = [];

                        viewFileTemplateRight: boolean = false;

                       productsExcelData: interfaces.master.IProductExcelData;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private customerService: interfaces.master.ICustomerService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            public bsLoadingOverlayService,
                            private fileTemplateService: interfaces.master.IFileTemplateService,
                            private menuService: interfaces.applicationcore.IMenuService,                
                            private entityId: number
                        ) {

                        }

                        getViewFileTemplateRight() {
                            this.viewFileTemplateRight = false;
                            return this.menuService.getGTSConnectMenuItem(703).get((result: interfaces.applicationcore.IMenu) => {
                                if (result.name) {
                                    this.viewFileTemplateRight = true;
                                }
                            }).$promise;
                        }


                        designTemplateClick_click(){
                            var url = '/#!/MasterData/FileTemplates';
                            window.open(url, '_blank');
                        }

                 

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        upload() {
                            this.disableSubmit = true;
                            

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'cominv.excelupload'
                            },
                                () => {

                                    return this.customerService.uploadCustomerConsigneeFromExcel().save(this.repositoryFile, (result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage) {
                                            this.$uibModalInstance.close(true);
                                        }

                                        this.$uibModalInstance.close(true);
                                    }, (errorResult) => {
                                        this.generalService.displayMessageHandler(errorResult.data);

                                        if (errorResult.data.Messages) {
                                            this.messages = [];
                                        }

                                        angular.forEach(errorResult.data.Messages, (o) => {
                                            angular.forEach(o.MessageItems, (n) => {
                                                this.messages.push(n);
                                            });
                                        });
                                        }).$promise;

                                });
                        }
                    },
                    controllerAs: "uploadExcelCtrl",
                    resolve: 
                    { 
                        entityId :  this.$rootScope.Entity.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadCustomers();
                    }
                });
            }

        }

        angular.module("app").controller("customerListCtrl", controllers.master.customerListCtrl);
    }
}